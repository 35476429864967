<template>
    <div :class="(card.animation_enabled) ? getAnimation(card.animation) : ''" :style="bioSectionThemeStyleBinding('background',getBioLinkAdds)"  class="bio_card p-2 d-flex align-items-center">
      <div class="text-center text-white">
        <div v-if="!card.url && !card.image" :style="card.style === 'thumb-2' ? 'border-radius:100%': ''"
             class="default_card pt-1 pl-2 pr-2"><i class="fas fa-image"></i></div>
        <div class="image" v-else
             :style="{ backgroundImage: `url('${card.image}')`,borderRadius: card.style === 'thumb-2' ? '100%': '10px'}"></div>

      </div>
      <div>
        <div class="d-block content p-2">
          <h6 :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)">{{ card.title }}</h6>
          <p :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)"><small>{{ card.description }}</small></p>
        </div>
      </div>
    </div>
</template>
<script>


export default ({
  props: [
    'isPreview',
    'card',
    'getBioLinkAdds'
  ],
  methods: {},
})
</script>
<style lang="less" scoped>
.bio_card {
  border-radius: 10px;
  background: white;
  .default_card {
    background-color: lightgray;
    font-size: xxx-large;
    border-radius: 10px;
    width: 80px;
    height: 80px;
  }

  .image {
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    place-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
  }

  .content {
    h6 {
      font-size: small;
      font-weight: bold;
    }
  }
}
</style>
